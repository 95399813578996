
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.customer-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  &__search {
    margin: 20px 0 0 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 0;
  }
  &__avatar {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
  }
  &__email {
    display: inline-block;
    margin: 0 0 0 16px;
    color: rgba(16, 24, 40, 0.4);
  }
  &__appointment-name {
    color: $color-primary-1-day;
  }
  &__email, &__appointment-name {
    font-size: 12px;
    line-height: 16px;
  }
  &__delete {
    cursor: pointer;
    margin-left: auto;
  }
}
